import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

interface HotelFurnitureProps {
  // data: any;
}
const HotelFurniture: React.FC<HotelFurnitureProps> = () => {
  return (
    <>
      <section className={`${styles['hotel-fntr-section']}`}>
        <div className="home-container-1600">
          <div className={`${styles['hotel-fntr-inner']}`}>
            <Link href={'#'} className={`${styles['hotel-fntr-img']}`}>
              <Image
                src={'/images/home-new1/hotel-furniture-banner.jpg'}
                alt="Mattress"
                title="Mattress"
                width={1900}
                height={355}
                loading="lazy"
                quality={100}
              />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};
export default HotelFurniture;
